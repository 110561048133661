import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTheme } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, List,  AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Select, MenuItem, Backdrop, CircularProgress, useMediaQuery } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const CreateService = ({projectId, open, handleClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async(e, id) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            name: data.get('sname'), 
            quantity: data.get('quantity'), 
            stype: data.get('stype'), 
            status: data.get('status'), 
            projectId: id, 
        };
        setLoading(true);
        await axios.post('/create-service', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Service was created'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error creating service. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress />
        </Backdrop>
    ), []);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}
        PaperProps={{
            sx: {
              height: '650px', 
            }
        }}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Create Service</Typography>
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={(e) => handleSubmit(e, projectId)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="sname">Task Name</FormLabel>
                            <OutlinedInput  required margin="dense" id="sname" name="sname" label="Task Name" placeholder="Task Name" type="text" fullWidth inputProps={{ maxLength: 50 }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="quantity">Quantity</FormLabel>
                            <OutlinedInput  required margin="dense" id="quantity" name="quantity" label="Quantity" placeholder="Quantity" type="number" fullWidth inputProps={{ maxLength: 5 }}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="stype">Task Type</FormLabel>
                            <OutlinedInput  required margin="dense" id="stype" name="stype" label="Task Type" placeholder="Task Type" type="text" fullWidth inputProps={{ maxLength: 30 }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <Select required id='status' name='status' label='Status'>
                                <MenuItem value='active'>Ongoing</MenuItem>
                                <MenuItem value='completed'>Completed</MenuItem>
                                <MenuItem value='pending'>Upcoming</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                </List>
                <Button type='submit' color="inherit" variant='outlined' sx={{ m: 2 }}>CREATE</Button>
            </Box>
            {loading ? loader : null}
        </Dialog>

    );

};

export default React.memo(CreateService);