import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { TextField, Autocomplete, Box, FormControl, FormLabel, Chip, Typography } from '@mui/material';

const colors = {
    "available": 'success',
    "on-work": 'info',
    "on-leave": 'error',
    "on-vacation": 'default', 
    "supervisor": 'warning', 
};

const ClientSearch = ({placeholder, label, onChange, type, id, name}) => {

    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [client, setClient] = React.useState({ id: id || '',  name: name || '' });

    const handleChange = React.useCallback((value) => {
        if(type === 'employee'){
            if(['on-work', 'on-vacation', 'on-leave'].includes(value?.status)){
                Swal.fire({
                    title: 'Employee already occupied', 
                    text: 'You can still add as this employee might be working in same project but different service.'
                });
            }
            setClient({ id: value?.id || '', name: value?.name || '' });
            onChange(value?.id || '', value?.name || '');
        }else{
            setClient({ id: value?.id || '', name: value?.name || '' });
            onChange(value?.id || '', value?.name || '', value);
        }
    }, [setClient, onChange, type]);

    const getClients = React.useCallback(async() => {
        setLoading(true);
        if(type === 'client'){
            await axios.get('/get-clients', {
                headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
            }).then((res) => setOptions(res.data)).catch((e) => {
                Swal.fire({
                    title: 'Error', 
                    text: 'Failed to get clients. Check browser logs for more info'
                });
                console.log(`Client search error: ${e}`);
            });
        }else if(type === 'employee'){
            await axios.get('/employee-search', {
                headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
            }).then((res) => setOptions(res.data)).catch((e) => {
                Swal.fire({
                    title: 'Error', 
                    text: 'Failed to get employees. Check browser logs for more info'
                });
                console.log(`Employee search error: ${e}`);
            });
        }
        setLoading(false);
    }, [type]);

    React.useEffect(() => {
        getClients();
    }, [getClients]);

    const dataArr = React.useMemo(() => (type === 'employee' ? 
    options.sort((a, b) => {
        if (a.role === 'supervisor' && b.role !== 'supervisor') return -1;
        if (b.role === 'supervisor' && a.role !== 'supervisor') return 1;
        return 0;
    }) : options), [options, type]);

    return (

        <FormControl>
            <FormLabel sx={{ display: 'flex', flexDirection: 'row' }}><Typography sx={{ fontWeight: 'bold' }}>{client.name || name || label}</Typography></FormLabel>
            <Autocomplete 
            autoHighlight 
            options={dataArr} 
            disabled={loading} 
            sx={{ width: 300 }} 
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => handleChange(value)}
            style={{ maxWidth: '280px' }}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <Box key={key} component="li" {...optionProps}>
                        {option?.name}
                        {type === 'employee' ? <Chip variant="filled" color={colors[option?.status]} label={option?.status} sx={{ ml: 1 }}/> : null}
                        {type === 'employee' && option?.role === 'supervisor' ? <Chip variant="filled" color={colors[option?.role]} label={option?.role} sx={{ ml: 1 }}/> : null}
                    </Box>
                );
            }}
            renderInput={(params) => (<TextField {...params} disabled={loading} placeholder={loading ? 'Loading' : placeholder} slotProps={{ input: { ...params.InputProps, type: 'search' } }}/>)}/>
        </FormControl>

    );

};

export default React.memo(ClientSearch);