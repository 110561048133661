import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Box, Typography, Button, Backdrop, CircularProgress, FormControl, FormLabel, List, ListItem, ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function Admin() {

    const [loading, setLoading] = React.useState(false);

    const [alignment, setAlignment] = React.useState('salary');

    const handleChange = (event, newAlignment) => setAlignment(newAlignment);

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const startDate = data.get('sdate');
        const endDate = data.get('edate');
        setLoading(true);
        await axios.get('/create-report', {
            params: {
                startDate,
                endDate, 
                type, 
            },
            responseType: 'blob', 
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}_report.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to create report. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    return (

        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Reports</Typography>
            </Box>
            <Box component="form" onSubmit={(e) => handleSubmit(e, alignment)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem>
                        <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange}>
                            <ToggleButton value="salary" sx={{ width: 100 }}>Salary</ToggleButton>
                            <ToggleButton value="project" sx={{ width: 100 }}>Project</ToggleButton>
                            <ToggleButton value="employee" sx={{ width: 100 }}>Employee</ToggleButton>
                        </ToggleButtonGroup>
                    </ListItem>
                    <ListItem>
                        <FormControl>
                            <FormLabel htmlFor="sdate">Start Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='sdate' name='sdate' disableFuture slotProps={{ textField: { required: true } }}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl>
                            <FormLabel htmlFor="edate">End Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='edate' name='edate' disableFuture slotProps={{ textField: { required: true } }}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <Button variant="outlined" type='submit' sx={{ mt: 2 }}>GENERATE REPORT</Button>
                    </ListItem>
                </List>
            </Box>
            {loading ? loader : null}
        </Box>

    );

};