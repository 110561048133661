import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { ser } from './gridData';
import { toDate } from '../../functions';
import { useTheme } from '@emotion/react';
import ServiceInfo from './services/ServiceInfo';
import CreateService from './services/CreateService';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClientSearch, CustomizedDataGrid } from '../../components';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Button, Dialog, List,  AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Select, MenuItem, Backdrop, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Tab, Tabs } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (

        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>

    );

};

const ProjectInfo = ({projectId, open, handleClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [index, setIndex] = React.useState(0);

    const handleIndex = (event, newValue) => {
        setIndex(newValue);
    };

    const [isOpen ,setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [projectData, setProjectData] = React.useState(null);

    const [services, setServices] = React.useState([]);
    const [serviceOpen, setServiceOpen] = React.useState(false);
    const [createServiceOpen, setCreateServiceOpen] = React.useState(false);

    const [status, setStatus] = React.useState(false);

    const toggle = () => setIsOpen(state => !state);

    const handleChange = React.useCallback((value) => {
        setProjectData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setProjectData]);

    const getInvoice = React.useCallback(async(num) => {
        const value = await axios.get('/get-invoice-by-number', {
            params: {
                invoiceNo: num, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            if(res.data?.length > 0) return true;
            else{
                Swal.fire({
                    title: 'No invoice', 
                    text: 'No invoice on this number'
                });
                return false;
            }
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get invoice. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
            return false;
        });
        return value;
    }, []);

    const onStatusChange = React.useCallback(async(e) => {
        if(e === 'completed'){
            const value = window.prompt('Enter Invoice number to close the project');
            if(value){
                if(value?.trim()?.length === 0) return;
                const invoiceNum = value.trim();
                setLoading(true);
                const exists = await getInvoice(invoiceNum);
                if(exists) setStatus(e);
                else setStatus(state => state);
                setLoading(false);
            }else setStatus(state => state);
        }else if(e === 'active' || e === 'pending') setStatus(e);
        else setStatus(state => state);
    }, [setStatus, getInvoice]);

    const getProjectData = async(id) => {
        setLoading(true);
        await axios.get('/get-project-info', {
            params: {
                projectId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setProjectData(res.data[0]);
            setStatus(res.data[0]?.status);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get project info. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const handleSubmit = async(e, id, props) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.currentTarget);
        const { clientId, supervisor } = props;
        const params = {
            id: id, 
            projectCode: Date.now().toString(), 
            clientId: clientId, 
            name: data.get('pname'), 
            projectDesc: data.get('pdesc'), 
            empId: supervisor, 
            startDate: data.get('sdate'), 
            endDate: data.get('edate'), 
            status: data.get('status'), 
            comments: data.get('comments'), 
        };
        await axios.post('/update-project', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Project was updated'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error updating project. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const delProject = React.useCallback(async(id) => {
        await axios.post('/delete-project', { id: id }, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Deleted', 
                text: 'Project was deleted'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error deleting project. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        handleClose();
    }, [handleClose]);

    const getServices = async(id) => {
        setLoading(true);
        await axios.get('/get-services', {
            params: {
                projectId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setServices(res.data);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get tasks. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const genReport = async(id) => {
        setLoading(true);
        await axios.get('/create-project-report', {
            params: {
                projectId: id, 
            },
            responseType: 'blob', 
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Project_Report.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to create project report. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    React.useEffect(() => {
        getProjectData(projectId);
        getServices(projectId);
    }, [projectId]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    const alerty = React.useMemo(() => (
        <Dialog open={isOpen} onClose={toggle}>
            <DialogTitle>Delete this project</DialogTitle>
            <DialogContent>
                <DialogContentText>Delete this project? This action is undoable!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => delProject(projectId)}>DELETE</Button>
                <Button onClick={toggle} >CANCEL</Button>
            </DialogActions>
        </Dialog>
    ), [isOpen, delProject, projectId]);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}
        PaperProps={{
            sx: {
              height: '650px', 
            }
        }}>
            <AppBar  sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Project Info</Typography>
                    <Button color="error" onClick={toggle}>DELETE PROJECT</Button>
                </Toolbar>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={index} onChange={handleIndex} variant="fullWidth">
                        <Tab label="Project Info" />
                        <Tab label="Task Info"/>
                    </Tabs>
                </Box>
            </AppBar>
            <CustomTabPanel value={index} index={0}>
                <Box component="form" onSubmit={(e) => handleSubmit(e, projectId, projectData)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <List>
                        <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="pname">Project Name</FormLabel>
                                <OutlinedInput  required margin="dense" id="pname" name="pname" label="Project Name" placeholder="Project Name" type="text" fullWidth inputProps={{ maxLength: 50 }} value={projectData?.name} onChange={e => handleChange({ name: e.target.value })}/>
                            </FormControl>
                        </ListItem>
                        <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="sdate">Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker required id='sdate' name='sdate' slotProps={{ textField: { required: true } }} value={dayjs(projectData?.startDate)} onChange={e => handleChange({ startDate: e })}/>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="edate">End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker required id='edate' name='edate' slotProps={{ textField: { required: true } }} value={dayjs(projectData?.endDate)} onChange={e => handleChange({ endDate: e })}/>
                                </LocalizationProvider>
                            </FormControl>
                        </ListItem>
                        <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <ClientSearch label='Client' placeholder='Search Clients' type='client' id={projectData?.clientId} name={projectData?.clientName} onChange={(id, name) => handleChange({ clientId: id, clientName: name })}/>
                            <ClientSearch label='Supervisor' placeholder='Search Employees' type='employee' id={projectData?.supervisor} name={projectData?.supervisorName} onChange={(id, name) => handleChange({ supervisor: id, supervisorName: name })}/>
                        </ListItem>
                        <ListItem>
                            <FormControl sx={{ mt: 2 }}>
                                <FormLabel htmlFor="status">Status</FormLabel>
                                <Select required id='status' name='status' label='Status' value={status} onChange={e => onStatusChange(e.target.value)}>
                                    <MenuItem value='active'>Ongoing</MenuItem>
                                    <MenuItem value='completed'>Completed</MenuItem>
                                    <MenuItem value='pending'>Upcoming</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="pdesc">Project Description</FormLabel>
                                <OutlinedInput multiline  required margin="dense" id="pdesc" name="pdesc" label="Project Description" placeholder="Project Description" type="text" fullWidth inputProps={{ maxLength: 200 }} value={projectData?.projectDesc} onChange={e => handleChange({ projectDesc: e.target.value })}/>
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <FormControl fullWidth>
                                <FormLabel htmlFor="comments">Comments</FormLabel>
                                <OutlinedInput multiline  margin="dense" id="comments" name="comments" label="Comments" placeholder="Comments" type="text" fullWidth inputProps={{ maxLength: 500 }} value={projectData?.comments} onChange={e => handleChange({ comments: e.target.value })}/>
                            </FormControl>
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl fullWidth>
                                <FormLabel>Created at: {toDate(projectData?.createdAt)}</FormLabel>
                            </FormControl>
                            <FormControl fullWidth>
                                <FormLabel>Last changed: {toDate(projectData?.lastChanged)}</FormLabel>
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <Button type='submit' color="inherit" variant='contained' fullWidth sx={{ mr: 2, ml: 2 }}>UPDATE</Button>
                        </ListItem>
                        <ListItem>
                            <Button color="inherit" variant='contained' fullWidth sx={{ m: 2, mt: 0 }} onClick={() => genReport(projectId)}>GENERATE REPORT</Button>
                        </ListItem>
                    </List>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={index} index={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button variant='outlined' sx={{ m: 2, ml: 0 }} onClick={() => setCreateServiceOpen(true)}>CREATE NEW TASK</Button>
                    <Button variant='outlined' sx={{ m: 2, ml: 1 }} onClick={() => getServices(projectId)}>RELOAD TASKS</Button>
                </Box>
                <CustomizedDataGrid columns={ser} rows={services} onClick={(e) => setServiceOpen(e)}/>
            </CustomTabPanel>
            {loading ? loader : null}
            {isOpen ? alerty : null}
            {createServiceOpen ? <CreateService projectId={projectId} open={createServiceOpen} handleClose={() => setCreateServiceOpen(false)}/> : null}
            {serviceOpen ? <ServiceInfo serviceId={serviceOpen} open={Boolean(serviceOpen)} handleClose={() => setServiceOpen(false)}/> : null}
        </Dialog>

    );

};

export default React.memo(ProjectInfo);