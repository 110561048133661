import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const CustomizedDataGrid = ({columns, rows, onClick}) => {

    const removeDupli = React.useCallback((arr) => {
        const map = new Map();
        return arr.filter(item => {
            if (!map.has(item?.id)) {
                map.set(item?.id, true);
                return true;
            }
            return false;
        });
    }, []);

    const newArr = React.useMemo(() => removeDupli(rows), [rows, removeDupli]);

    return (

        <DataGrid
        autoHeight
        rows={newArr}
        localeText={{
            noRowsLabel: 'No Data Available'
        }}
        columns={columns}
        onRowClick={(obj) => onClick(obj.id)}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
        initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        slotProps={{
            filterPanel: {
                filterFormProps: {
                    logicOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                    },
                    columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto', width: 'min-content !important' },
                    },
                    operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto', width: 'min-content !important' },
                    },
                    valueInputProps: {
                        InputComponentProps: {
                            variant: 'outlined',
                            size: 'small',
                            sx: { width: 'min-content !important' },
                        },
                    },
                },
            },
        }}/>

    );

};

export default React.memo(CustomizedDataGrid);