import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DataChart, StatCard } from '../../components';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';
import { Grid2 as Grid, Box, Typography, Backdrop, CircularProgress, IconButton } from '@mui/material';

export default function Dashboard() {

    const [loading, setLoading] = React.useState(true);

    const [data, setData] = React.useState({
        client: 0, 
        amc: 0, 
        notamc: 0, 
        totalProjects: 0, 
        pendingProjects: 0, 
        activeProjects: 0, 
        completedProjects: 0, 
        invoice: 0, 
    });

    const getData = React.useCallback(async() => {
        setLoading(true);
        await axios.get('/get-count', {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setData(res.data);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get counts. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    }, [setData]);

    React.useEffect(() => {
        getData();
    }, [getData]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    return (

        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Dashboard</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton onClick={getData}>
                        <RefreshIcon/>
                    </IconButton>
                </Box>
            </Box>
            <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <DataChart 
                    title='Projects' 
                    totalLabel='Total'
                    totalValue={data?.totalProjects}
                    data={[
                        { label: 'Ongoing', value: data?.activeProjects },
                        { label: 'Upcoming', value: data?.pendingProjects },
                        { label: 'Completed', value: data?.completedProjects },
                    ]}/>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <DataChart 
                    title='Clients' 
                    totalLabel='Total'
                    totalValue={data?.client}
                    data={[
                        { label: 'AMC', value: data?.amc },
                        { label: 'Non-AMC', value: data?.notamc },
                    ]}/>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
                    <StatCard title='Outstanding Invoices' value={data?.invoice}/>
                </Grid>
            </Grid>
            {loading ? loader : null}
        </Box>

    );

};