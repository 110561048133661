import * as React from 'react';
import { Chip } from '@mui/material';

function calculateDaysLeft(expiryDate) {

    if (!expiryDate) {
      return false; 
    }

    const today = new Date();
    const expiry = new Date(expiryDate);

    const timeDiff = expiry - today;

    if (timeDiff <= 0) {
      return "Expired"; 
    }

    const daysInMs = 24 * 60 * 60 * 1000; 
    const daysLeft = Math.ceil(timeDiff / daysInMs); 

    if(daysLeft < 30) {
        return daysLeft + (daysLeft === 1 ? " d" : " days"); 
    }else if(daysLeft < 365) {
        const monthsLeft = Math.floor(daysLeft / 30); 
        return monthsLeft + (monthsLeft === 1 ? " mon" : " mons"); 
    }else{
        const yearsLeft = Math.floor(daysLeft / 365); 
        return yearsLeft + (yearsLeft === 1 ? " yr" : " yrs"); 
    }

};

export function renderExpiry(date){

    const label = calculateDaysLeft(date);

    if(!label) return null;

    return <Chip label={"AMC expires in "+label} color='info' size="small" />;
};

function renderStatus(status) {

    return <Chip label={status} color='success' size="small" />;

};

function renderStatus2(status) {

    const value = status === 1 ? 'YES' : 'NO';

    return <Chip label={value} color='default' size="small" />;

};



export const columns = [
    {
        field: "serial-number",
        headerName: "#",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'name', 
        headerName: 'Client Name', 
        flex: 1.5, 
        minWidth: 200 
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => renderStatus(params.value),
    },
    {
        field: 'mob1',
        headerName: 'Phone number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'email',
        headerName: 'Email',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'clientType',
        headerName: 'Client Type',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 60,
    },
    {
        field: 'amcexpiry',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 120,
        renderCell: (params) => renderExpiry(params.value),
    },
    {
        field: 'amc',
        headerName: 'AMC',
        flex: 0.5,
        minWidth: 30,
        renderCell: (params) => renderStatus2(params.value),
    },
];