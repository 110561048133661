import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTheme } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Button, Dialog, List,  AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Select, MenuItem, Backdrop, CircularProgress, useMediaQuery } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEmployee = ({admin, open, handleClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = React.useState(false);

    const [role, setRole] = React.useState('none');

    const handleSubmit = async(e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            name: data.get('empname'), 
            status: data.get('status'), 
            role: data.get('role'), 
            empcode: data.get('empcode'), 
            dob: data.get('dob'), 
            gender: 'M', 
            phone: data.get('phone'), 
            email: data.get('email'), 
            address: data.get('address'), 
            jobTitle: data.get('jobTitle'), 
            emptype: data.get('emptype'), 
            salary: data.get('salary'), 
            doj: data.get('doj'), 
        };
        setLoading(true);
        await axios.post('/create-employee', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Employee was created'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Error creating employee. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress />
        </Backdrop>
    ), []);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Create {admin ? 'Admin' : 'Employee'}</Typography>
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="empname">{admin ? 'Admin' : 'Employee'} Name</FormLabel>
                            <OutlinedInput  required margin="dense" id="empname" name="empname" label="Name" placeholder="Name" type="text" fullWidth inputProps={{ maxLength: 50 }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="empcode">{admin ? 'Admin' : 'Employee'} Code</FormLabel>
                            <OutlinedInput required margin="dense" id="empcode" name="empcode" label="Code" placeholder="Code" type="text" fullWidth inputProps={{ maxLength: 20 }}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <Select required id='status' name='status' label='Status' sx={{ width: 130 }}>
                                <MenuItem value='on-work'>On work</MenuItem>
                                <MenuItem value='on-vacation'>On vacation</MenuItem>
                                <MenuItem value='on-leave'>On leave</MenuItem>
                                <MenuItem value='available'>Available</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="role">Role</FormLabel>
                            <Select required id='role' name='role' label='Role' sx={{ width: 130 }} value={role} onChange={e => setRole(e.target.value)}>
                                {admin ? <MenuItem value='super-admin'>Super Admin</MenuItem> :null}
                                {admin ? <MenuItem value='admin'>Admin</MenuItem> : null}
                                <MenuItem value='supervisor' selected={role === 'supervisor'}>Supervisor</MenuItem>
                                <MenuItem value='none' selected={role === 'none'}>None</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="doj">Date of Joining</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker id='doj' name='doj'/>
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="dob">Visa Expiry</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker id='dob' name='dob'/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="phone">Phone</FormLabel>
                            <OutlinedInput required margin="dense" id="phone" name="phone" label="Phone" placeholder="Phone with Country Code" type="tel" fullWidth inputProps={{ maxLength: 15 }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <OutlinedInput margin="dense" id="email" name="email" label="Email" placeholder="Employee@email.com" type="email" fullWidth inputProps={{ maxLength: 50 }}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <OutlinedInput margin="dense" id="address" name="address" label="Address" placeholder="Address" type="text" fullWidth inputProps={{ maxLength: 200 }}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="jobTitle">Job Title</FormLabel>
                            <Select required id='jobTitle' name='jobTitle' label='Job Title' sx={{ width: 130 }}>
                                <MenuItem value='Technician'>Technician</MenuItem>
                                <MenuItem value='Helper'>Helper</MenuItem>
                                <MenuItem value='Instructor'>Instructor</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="emptype">{admin ? 'Admin' : 'Employee'} Type</FormLabel>
                            <OutlinedInput required margin="dense" id="emptype" name="emptype" label={admin ? 'Admin Type' : 'Employee Type'} placeholder={admin ? 'Admin Type' : 'Employee Type'} type="text" fullWidth inputProps={{ maxLength: 20 }}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl>
                            <FormLabel htmlFor="salary">Salary</FormLabel>
                            <OutlinedInput required margin="dense" id="salary" name="salary" label="Salary" placeholder="Salary" type="number" fullWidth inputProps={{ maxLength: 8 }}/>
                        </FormControl>
                    </ListItem>
                </List>
                <Button type='submit' color="inherit" variant='outlined' sx={{ m: 2 }}>CREATE</Button>
            </Box>
            {loading ? loader : null}
        </Dialog>

    );

};

export default React.memo(CreateEmployee);